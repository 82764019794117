<template>
  <b-navbar toggleable>
    <b-navbar-toggle target="navbar-toggle-collapse" v-on:click="changeMenu()">
      <template :v-slot:default="{ expanded }">
        <div>
          <img id="menuImg" src="../assets/icon_menu.png" alt />
          <p id="menuText">
            Menü schließen
            <img src="../assets/arrow_up_white.png" alt />
          </p>
        </div>
      </template>
    </b-navbar-toggle>

    <b-nav-text id="navtitle" class="d-none d-lg-block">{{
      $store.getters.getActiveCategoryName
    }}</b-nav-text>

    <b-collapse id="navbar-toggle-collapse" is-nav>
      <b-navbar-nav class>
        <div
          class="category"
          v-for="(category, index) in this.$store.state.categories"
          :key="index"
        >
          <b-nav-item
            v-on:click.prevent="changeMenu(category)"
            :href="category.href"
            >{{ category.name }}</b-nav-item
          >
        </div>
      </b-navbar-nav>
    </b-collapse>
  </b-navbar>
</template>

<script>
import ApiRequest from "@/api/apiRequest";

export default {
  data() {
    return {
      toggle: false,
      expanded: true,
    };
  },
  methods: {
    changeMenu(category) {
      this.toggle = !this.toggle;
      this.changeMenuStyle();
      if (category !== undefined) {
        this.loadProducts(category);
        this.$root.$emit("page-event");
        this.$store.commit("changeSearchName", "");
      }
    },
    changeMenuStyle() {
      let menuImg = document.getElementById("menuImg");
      let menuText = document.getElementById("menuText");
      let navtitle = document.getElementById("navtitle");
      let menuButton = document.getElementById("menu-button");
      if (this.toggle) {
        menuImg.style.visibility = "hidden";
        menuText.style.visibility = "visible";
        navtitle.style.visibility = "hidden";
        menuButton.style.zIndex = 3;
        this.changeNavBarColor("#2c2c2c");
      } else {
        menuImg.style.visibility = "visible";
        menuText.style.visibility = "hidden";

        setTimeout(() => {
          this.changeNavBarColor("transparent");
          navtitle.style.visibility = "visible";
          menuButton.style.zIndex = 0;
        }, 300);
      }
    },
    changeNavBarColor(color) {
      let navBar = document.getElementsByClassName("navbar")[0];
      navBar.style.backgroundColor = color;
    },
    resizeEventHandler() {
      let navBar = document.getElementsByClassName("navbar")[0];
      let width =
        navBar.parentElement.parentElement.getBoundingClientRect().width -
        navBar.parentElement.offsetLeft;
      navBar.style.width = width + "px";
    },
    loadProducts(category) {
      if (!this.toggle) {
        if (category.id === -1) {
          this.$store.commit("changeActiveCategory", "");
        } else {
          this.$store.commit("changeActiveCategory", category.id);
        }
        this.$store.commit("changePaginationPage", 1);

        ApiRequest.productRequest();
        ApiRequest.filterRequest();
        
        this.$root.$children[0].routerPush();
      }
    },
  },
  mounted: function () {
    this.$nextTick(function () {
      // Will be executed when the DOM is ready
      window.addEventListener("resize", this.resizeEventHandler);
      this.resizeEventHandler();
    });
  },
  watch: {
    $route: function () {
      if (this.toggle) {
        document.getElementById("menuImg").click();
      }
    },
  },
};
</script>

<style scoped>
#container {
  padding-left: 50px;
}
.navbar {
  background-color: transparent;
  position: relative;
  padding: 0.7rem 1rem;
}

.navbar-toggler {
  border: none;
}

#navtitle {
  color: white;
  position: absolute;
  left: 65px;
  white-space: nowrap;
  padding-bottom: 0px;
}

#navbar-toggle-collapse li a {
  color: #9b9b9b;
}
#navbar-toggle-collapse li a:hover {
  color: #ff9235;
}

.navbar-collapse {
  margin-top: 1rem;
}

#menuText {
  visibility: hidden;
  position: absolute;
  color: white;
  white-space: nowrap;
  top: 15px;
  left: 30px;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
}

#menuText img {
  padding-left: 1rem;
}
button {
  outline: none;
  outline-width: 0;
}
.navbar-brand {
  padding: 0 15px;
  height: 80px;
  line-height: 80px;
}
.navbar-toggle {
  margin-top: 23px;
  padding: 9px 10px !important;
}
#menuImg {
  padding-top: 0.5rem;
}
#menuImg img {
  padding-left: 1rem;
}
.category {
  padding-left: 15px;
}
@media screen and (max-width: 576px) {
  nav {
    width: 100% !important;
  }
}
@media screen and (min-width: 768px) {
  .navbar {
    padding: 1.2rem 1rem;
  }
  #navtitle {
    padding-top: 14px;
    font-size: 1.2rem;
  }
  #menuImg {
    padding-top: 0.75rem;
    display: inline;
  }
  #menuText {
    font-size: 1.2rem;
    padding-top: 14px;
  }
  button {
  width: 320px;
  z-index: 2;
  text-align: left;
}
}
</style>