export default {
  mapToArr(data) {
    let arr = []
    for (let key in data) {
      if (typeof (data[key]) === 'object') {
        try {
          if (key != "_jv") {
            arr.push(JSON.parse(JSON.stringify(data[key])));
          }
        } catch (e) {
          console.error(e);
        }
      }
    }
    return arr;
  },
  mapFilterObjToArr(obj) {
    let result = [];
    Object.keys(obj).forEach((key) => {
      let name = "";
      if (obj[key].name == "-null-") {
        name = "Unbekannt";
      } else {
        name = obj[key].name;
      }
      result.push({ value: key, text: name });
    });
    return this.sortFilterArr(result);
  },
  sortFilterArr(arr) {
    return arr.sort((a, b) => (a.text.toLowerCase() > b.text.toLowerCase() ? 1 : -1));
  },
  mapFilterArrToIdArr(arr) {
    let result = [];
    Object.keys(arr).forEach((key) => {
      result.push(arr[key].value);
    });
    return result;
  }
}