<template>
  <div class="container pt-3 pt-md-5">
    
      <h4 class="orange-heading pl-3">Datenschutz</h4>
    
    <div class="container mt-md-n3">
      <p>Im Folgenden möchten wir Sie über die durch uns durchgeführte Verarbeitung personenbezogener Daten bei Besuch unsere Webseite nach Maßgabe der EU-Datenschutz-Grundverordnung (im Folgenden: DSGVO) hinweisen.</p>
      <p>Nachstehende Regelungen informieren Sie über die Art, den Umfang und den Zweck der Erhebung, die Nutzung und die Verarbeitung von personenbezogenen Daten durch die<p>

      <p class="pl-3">
        LoCoNET GmbH<br/>
        Marlene-Dietrich-Allee 15<br/>
        14482 Potsdam<br/>
        Telefon: 0331/73098829<br/>   
        E-Mail: kontakt@loconet.info
      </p>

      <p>nachfolgend „wir“ bzw. „uns“.</p>
      <p>Bitte lesen Sie sich unsere Datenschutzerklärung sorgfältig durch. Sollten Sie Fragen oder Anmerkungen zu dieser Datenschutzerklärung haben, können Sie diese jederzeit an die unter Ziffer 1 angegebene E-Mail-Adresse richten.</p>

      <div class="greyline mb-4"></div>

      <h2>1. Name und Kontaktdaten des für die Verarbeitung Verantwortlichen sowie des Datenschutzbeauftragten </h2>
        <p>Verantwortliche Stelle für die Erhebung, Verarbeitung und Nutzung Ihrer personenbezogenen Daten ist die LoCoNET GmbH, Marlene-Dietrich-Allee 15, 14482 Potsdam (im Folgenden: LoCoNET).</p>
        <p>Sofern Sie der Erhebung, Verarbeitung oder Nutzung Ihrer Daten durch die LoCoNET nach Maßgabe dieser Datenschutzbestimmungen insgesamt oder für einzelne Maßnahmen widersprechen wollen, können Sie Ihren Widerspruch per E-Mail, Fax oder Brief an die LoCoNET mit folgenden Kontaktdaten senden:</p>
        <p class="pl-3">
          LoCoNET GmbH<br/>
          Marlene-Dietrich-Allee 15<br/>
          14482 Potsdam<br/>
          Telefon: 0331/73098829<br/>
          Fax: 0331 730 988 21<br/>   
          E-Mail: datenschutz@loconet.info
        </p>
        <p>Auf schriftliche Anfrage werden wir Sie auch jederzeit unentgeltlich gern über die von uns zu Ihrer Person gespeicherten Daten informieren.</p>

      <h2>2.1 Eingebundene Angebote von Drittanbietern </h2>
        <h3>2.1 Allgemeines </h3>
          <p>In Zusammenarbeit mit Kooperationspartnern binden wir auf unserem Online-Angebot auch Angebote von Drittanbietern ein. Diese Angebote sind keine Dienstleistungen der LoCoNET GmbH. Die verantwortlichen Stellen im Sinne des Datenschutzes sind hierfür die nachfolgend gelisteten Unternehmen.</p>
          
        <h3>2.2. Liste der Drittanbieter</h3>
          <b-button v-b-toggle.thirdparties class="m-1">Liste der Drittanbieter ansehen</b-button>
          <b-collapse id="thirdparties">
            <ul>
              <li>11a-Apofy GmbH, Kurt-Schumacher-Str. 49, 65232 Taunusstein, Deutschland. Weitere Details entnehmen Sie bitte deren AGB und Hinweisen zum Datenschutz auf der Website: https://apofy.de</li>
              <li>A. Hoffmann GmbH, Luisenburgstr. 16, 94086 Bad Griesbach, Deutschland. Weitere Details entnehmen Sie bitte deren AGB und Hinweisen zum Datenschutz auf der Website: https://www.brustgeschirr.com</li>
              <li>ABE-Motorradzubehör, Erlacher Straße 19, 91161 Hilpoltstein, Deutschland. Weitere Details entnehmen Sie bitte deren AGB und Hinweisen zum Datenschutz auf der Website: https://abe-motorradzubehoer.de</li>
              <li>AfB gemeinnützige GmbH, Willi-Bleicher-Straße 2, 52353 Düren, Deutschland. Weitere Details entnehmen Sie bitte deren AGB und Hinweisen zum Datenschutz auf der Website: https://www.afbshop.de</li>
              <li>Agilpharma GmbH, Allgäuer Straße 9, 87719 Mindelheim, Deutschland. Weitere Details entnehmen Sie bitte deren AGB und Hinweisen zum Datenschutz auf der Website: https://www.agilpharma.de</li>
              <li>AHT Fashion GmbH, Lüttinger Str. 25, 46509 Xanten, Deutschland. Weitere Details entnehmen Sie bitte deren AGB und Hinweisen zum Datenschutz auf der Website: http://www.anzuege.de</li>
              <li>ALESYA ORLÓVA, Eulenkamp 2, 22049 Hamburg, Deutschland. Weitere Details entnehmen Sie bitte deren AGB und Hinweisen zum Datenschutz auf der Website: https://www.alesyaorlova.com</li>
              <li>allymarket GmbH & Co. KG, Peterstr. 3, 99084 Erfurt, Deutschland. Weitere Details entnehmen Sie bitte deren AGB und Hinweisen zum Datenschutz auf der Website: https://www.comfortsun-shop.de</li>
              <li>Ankerwelt, Liegnitzer Straße 19, 31226 Peine, Deutschland. Weitere Details entnehmen Sie bitte deren AGB und Hinweisen zum Datenschutz auf der Website: https://www.ankerwelt.com</li>
              <li>Antares Im- und Export GmbH, Pankower Str. 16, 13156 Berlin, Deutschland. Weitere Details entnehmen Sie bitte deren AGB und Hinweisen zum Datenschutz auf der Website: https://www.software.de</li>
              <li>Antares Im- und Export GmbH, Pankower Str. 16, 13156 Berlin, Deutschland. Weitere Details entnehmen Sie bitte deren AGB und Hinweisen zum Datenschutz auf der Website: https://www.softwarebilliger.de</li>
              <li>ARNSTADT Kristall GmbH, Bierweg 27, 99310 Arnstadt, Deutschland. Weitere Details entnehmen Sie bitte deren AGB und Hinweisen zum Datenschutz auf der Website: https://www.arnstadtkristall-shop.de</li>
              <li>bademantel-online.de, Inh.: Arus Germany GmbH, Stresemannstr. 54, 28207 Bremen, Deutschland. Weitere Details entnehmen Sie bitte deren AGB und Hinweisen zum Datenschutz auf der Website: https://bademantel-online.de</li>
              <li>Baustoffhandel Carstensen, Einzelunternehmen, Jörg Carstensen, Dorfstraße 46, 25923 Braderup, Deutschland. Weitere Details entnehmen Sie bitte deren AGB und Hinweisen zum Datenschutz auf der Website: https://www.trapezblech-onlineshop.de</li>
              <li>BETRON AG, Konstanzerstr. 37, CH-8274 Tägerwilen. Weitere Details entnehmen Sie bitte deren AGB und Hinweisen zum Datenschutz auf der Website: https://www.best-in-coffee.de</li>
              <li>bigE, Andreas Scheben, Antoniusstr. 7, 53909 Zülpich, Deutschland. Weitere Details entnehmen Sie bitte deren AGB und Hinweisen zum Datenschutz auf der Website: https://www.bige.de</li>
              <li>Biker-Zone Motorradbekleidung, Beinheimer Str. 4, 76437 Rastatt-Wintersdorf, Deutschland. Weitere Details entnehmen Sie bitte deren AGB und Hinweisen zum Datenschutz auf der Website: https://www.biker-zone.com</li>
              <li>BionicWater UG (haftungsbeschränkt), Moellberger Heide, 9, 32457 Porta Westfalica, Deutschland. Weitere Details entnehmen Sie bitte deren AGB und Hinweisen zum Datenschutz auf der Website: https://www.bionicwater.com</li>
              <li>Bluespoon GmbH, Toyota-Allee 33, 50858 Köln, Deutschland. Weitere Details entnehmen Sie bitte deren AGB und Hinweisen zum Datenschutz auf der Website: https://www.bluespoon.de</li>
              <li>Blume Ideal, Gerardus Braas, Handelsstraße 12, 26736 Krummhörn, Deutschland. Weitere Details entnehmen Sie bitte deren AGB und Hinweisen zum Datenschutz auf der Website: https://www.blumeideal.de</li>
              <li>Blumenfee - Der Blumenversand, Maren Hellwig, Vor dem Tore 14, 39343 Rottmersleben, Deutschland. Weitere Details entnehmen Sie bitte deren AGB und Hinweisen zum Datenschutz auf der Website: https://www.blumenfee.de</li>
              <li>Blumixx GmbH, Garvensstr. 10, 30519 Hannover, Deutschland. Weitere Details entnehmen Sie bitte deren AGB und Hinweisen zum Datenschutz auf der Website: https://www.blumixx.de</li>
              <li>BO Berlin Organics GmbH, Bergstraße 67, 10115 Berlin, Deutschland. Weitere Details entnehmen Sie bitte deren AGB und Hinweisen zum Datenschutz auf der Website: https://www.berlinorganics.de</li>
              <li>Bodyshop3000 GmbH, Roger Bittel, Elzstraße 5, 77975 Ringsheim, Deutschland. Weitere Details entnehmen Sie bitte deren AGB und Hinweisen zum Datenschutz auf der Website: https://bodyshop3000.de</li>
              <li>Boogs 24 GmbH, Grosser Mühlenweg 18, 41564 Kaarst, Deutschland. Weitere Details entnehmen Sie bitte deren AGB und Hinweisen zum Datenschutz auf der Website: https://boogs-home.com </li>
              <li>Braufabrik Events UG, (haftungsbeschränkt), Essener Straße 4, 66606 St. Wendel, Deutschland. Weitere Details entnehmen Sie bitte deren AGB und Hinweisen zum Datenschutz auf der Website: https://www.braufabrik.de</li>
              <li>Breuer Haus- & Heimtextilien GmbH, Markt 24-26, 52249 Eschweiler, Deutschland. Weitere Details entnehmen Sie bitte deren AGB und Hinweisen zum Datenschutz auf der Website: https://bettenhaus-breuer.de</li>
              <li>BS-DesignZ e.U., Zoran Kostic, BSc. MA, Rudolf Zellergasse 58-60 / 12 / 12, 1230 Wien, Österreich. Weitere Details entnehmen Sie bitte deren AGB und Hinweisen zum Datenschutz auf der Website: https://blackswandesignz.com</li>
              <li>Burkhard Hollunder GmbH, Lahnstraße 48, 57250 Netphen, Deutschland. Weitere Details entnehmen Sie bitte deren AGB und Hinweisen zum Datenschutz auf der Website: https://akkuwelt.de</li>
              <li>byOz©, Konrad-Adenauer-Ring 38, 65187 Wiesbaden, Deutschland. Weitere Details entnehmen Sie bitte deren AGB und Hinweisen zum Datenschutz auf der Website: https://byoz.eu</li>
              <li>CARCLIX, J. H. Tönnjes GmbH, Syker Straße 201, 27751 Delmenhorst, Deutschland. Weitere Details entnehmen Sie bitte deren AGB und Hinweisen zum Datenschutz auf der Website: https://www.carclix.de</li>
              <li>Cheressi Concepts e.K, Nadim Moufarrej, Elisabethstr. 17, 40880 Ratingen, Deutschland. Weitere Details entnehmen Sie bitte deren AGB und Hinweisen zum Datenschutz auf der Website: https://www.capellishop.de</li>
              <li>CY&ER Handels GmbH & Co. KG, Julia Nzuri, Burgstraße 54, 53177 Bonn, Deutschland. Weitere Details entnehmen Sie bitte deren AGB und Hinweisen zum Datenschutz auf der Website:  https://julia-nzuri.de</li>
              <li>DeltaStar Nutrients bv Floriadelaan 10, 5928 RK Venlo, Niederlande. Weitere Details entnehmen Sie bitte deren AGB und Hinweisen zum Datenschutz auf der Website: https://www.deltastar.nl</li>
              <li>EMS Eurabo Marketing Service GmbH & Co. KG, Blumenhaller Weg 88, 49078 Osnabrück, Deutschland. Weitere Details entnehmen Sie bitte deren AGB und Hinweisen zum Datenschutz auf der Website: https://www.aboeinfach.de</li>
              <li>FINNTO GmbH, Joachim-Friedrich-Straße 37-38, 10711 Berlin, Deutschland. Weitere Details entnehmen Sie bitte deren AGB und Hinweisen zum Datenschutz auf der Website:  https://finnto.de</li>
              <li>F.Twents GmbH, Baumeister-Bühler-Str 13, 50859 Köln, Deutschland. Weitere Details entnehmen Sie bitte deren AGB und Hinweisen zum Datenschutz auf der Website: https://www.alarm-laden.de</li>
              <li>H4G GmbH, Dr.-Eckener-Str. 14a, 27793 Wildeshausen, Deutschland. Weitere Details entnehmen Sie bitte deren AGB und Hinweisen zum Datenschutz auf der Website:  https://www.led-kerzen.de</li>
              <li>Höhenrainer Delikatessen GmbH, Lauser Str. 1, 83620 Großhöhenrain, Deutschland. Weitere Details entnehmen Sie bitte deren AGB und Hinweisen zum Datenschutz auf der Website:  https://www.hoehenrainer.de</li>
              <li>Hoppediz GmbH & Co. KG, Zum Scheider Feld 45, 51467 Bergisch Gladbach, Deutschland. Weitere Details entnehmen Sie bitte deren AGB und Hinweisen zum Datenschutz auf der Website:  https://hoppediz.de</li>
              <li>Hornschuch-Markt GmbH, Salinenstraße 1, 74679 Weißbach, Deutschland. Weitere Details entnehmen Sie bitte deren AGB und Hinweisen zum Datenschutz auf der Website: https://www.d-c-fix.com</li>
              <li>HUAWEI TECHNOLOGIES Deutschland GmbH, Hansaallee 205, 40549 Düsseldorf, Deutschland. Weitere Details entnehmen Sie bitte deren AGB und Hinweisen zum Datenschutz auf der Website:  https://www.huawei.com</li>
              <li>Ibendahl & Thomsen GmbH, Dorfstraße 2, 24879 Idstedt, Deutschland. Weitere Details entnehmen Sie bitte deren AGB und Hinweisen zum Datenschutz auf der Website: https://www.gabiona.de</li>
              <li>Impag Steinhaus Vertriebs-GmbH, Gildestraße 1, Gewerbegebiet Laggenbeck II., 49477 Ibbenbüren, Deutschland. Weitere Details entnehmen Sie bitte deren AGB und Hinweisen zum Datenschutz auf der Website:  https://impag-schutzgitter.de</li>
              <li>impulsprojekte UG, Rittergasse 3E, 94315 Straubing, Deutschland. Weitere Details entnehmen Sie bitte deren AGB und Hinweisen zum Datenschutz auf der Website: https://www.andreas-roth.de</li>
              <li>inba arbeitsschutz Georg J. Hoffmann GmbH & Co. Vertriebs KG, Günnigfelder Str. 70 - 76, 44866 Bochum, Deutschland. Weitere Details entnehmen Sie bitte deren AGB und Hinweisen zum Datenschutz auf der Website:  https://inba.de</li>
              <li>Intan international AG, Gersauerstrasse 21, CH-6440 Brunnen. Weitere Details entnehmen Sie bitte deren AGB und Hinweisen zum Datenschutz auf der Website: https://www.abo-direkt.ch</li>
              <li>Intan media services GmbH, Blumenhaller Weg 88, 49078 Osnabrück, Deutschland. Weitere Details entnehmen Sie bitte deren AGB und Hinweisen zum Datenschutz auf der Website: https://www.abo-direkt.at</li>
              <li>iQ-Company AG, Am Klingenweg 12, 65396 Walluf, Deutschland. Weitere Details entnehmen Sie bitte deren AGB und Hinweisen zum Datenschutz auf der Website:  https://iq-uv.com</li>
              <li>I.Sindermann Holzvertrieb, Rochusstr. 33, 50827 Köln, Deutschland. Weitere Details entnehmen Sie bitte deren AGB und Hinweisen zum Datenschutz auf der Website:  https://www.holzundgarten.de"</li>
              <li>IT-NERD24 GmbH, Hafenweg 22, 48155 Münster, Deutschland. Weitere Details entnehmen Sie bitte deren AGB und Hinweisen zum Datenschutz auf der Website:  https://it-nerd24.de</li>
              <li>Jacques Lemans GmbH, Jacques-Lemans-Straße 1, 9300 St. Veit an der Glan, Österreich. https://www.jacques-lemans.com</li>
              <li>Janine Design GmbH, Up de Hacke 15-17, 48691 Vreden, Deutschland. Weitere Details entnehmen Sie bitte deren AGB und Hinweisen zum Datenschutz auf der Website:  https://www.janine.de</li>
              <li>KMP WebSales GmbH, Dresdner Str. 30, 01936 Laußnitz, Deutschland. Weitere Details entnehmen Sie bitte deren AGB und Hinweisen zum Datenschutz auf der Website: https://www.homegarten24.de</li>
              <li>Knoxsafe GmbH Bronnerstr. 7, 44141 Dortmund, Deutschland. Weitere Details entnehmen Sie bitte deren AGB und Hinweisen zum Datenschutz auf der Website: https://www.knoxsafe.de</li>
              <li>Koste / Weitzel GbR, In den Ziegelwiesen 25/1, 69168 Wiesloch, Deutschland. Weitere Details entnehmen Sie bitte deren AGB und Hinweisen zum Datenschutz auf der Website: https://www.artikel-fuchs.de</li>
              <li>Kowalschik E-Commerce GmbH & Co. KG, Böckersstr. 12, 33397  Rietberg, Deutschland. Weitere Details entnehmen Sie bitte deren AGB und Hinweisen zum Datenschutz auf der Website:  https://www.immer-besser.de</li>
              <li>Kramer 4HS, Axel Kramer, Hohlstrasse 303, 8004 Zürich, Schweiz. Weitere Details entnehmen Sie bitte deren AGB und Hinweisen zum Datenschutz auf der Website: https://www.baamsports.net</li>
              <li>LIMAS GmbH, Otto-Esser-Str. 9b, 65451 Kelsterbach, Deutschland. Weitere Details entnehmen Sie bitte deren AGB und Hinweisen zum Datenschutz auf der Website: https://www.limasbaby.de</li>
              <li>Linens Rolf Herrmann e.K. Kirchpatt 21, 49525 Lengerich, Deutschland. Weitere Details entnehmen Sie bitte deren AGB und Hinweisen zum Datenschutz auf der Website: https://www.belily-world.com/de</li>
              <li>marhelgroup, Im Unholder Weg 9, 71696 Möglingen, Deutschland. Weitere Details entnehmen Sie bitte deren AGB und Hinweisen zum Datenschutz auf der Website:  https://www.just3dp.com</li>
              <li>Michele Di Gennaro, italienische Feinkost, Blumenstraße 2, 73666 Baltmannsweiler, Deutschland. Weitere Details entnehmen Sie bitte deren AGB und Hinweisen zum Datenschutz auf der Website: https://www.buonissimo-world.de</li>
              <li>Mohamed Al-Hakim, Beusselstr. 44 N-Q Gebäude 33D - Büro 2, 10553 Berlin, Deutschland. Weitere Details entnehmen Sie bitte deren AGB und Hinweisen zum Datenschutz auf der Website: https://am48.de</li>
              <li>MSOfficehandel UG (haftungsbeschränkt), Jahnstr. 1, 31162 Bad Salzdetfurth, Deutschland. Weitere Details entnehmen Sie bitte deren AGB und Hinweisen zum Datenschutz auf der Website: https://www.blitzhandel24.de</li>
              <li>muso koroni e.U., Josefstädter Straße 33, A-1080 Wien, Österreich.  Weitere Details entnehmen Sie bitte deren AGB und Hinweisen zum Datenschutz auf der Website:  https://www.muso-koroni.com</li>
              <li>NET Online-Versand GmbH & Co. KG, Frankenstr. 152, 90461 Nürnberg, Deutschland. Weitere Details entnehmen Sie bitte deren AGB und Hinweisen zum Datenschutz auf der Website:  https://it-versand.com</li>
              <li>Nicole Haschke, Better Western Wear, Stadtweg 20a, 63589 Linsengericht, Deutschland. Weitere Details entnehmen Sie bitte deren AGB und Hinweisen zum Datenschutz auf der Website: https://www.betterwesternwear.de</li>
              <li>NordFit Sporternährung Verw. UG (haftungsbeschränkt) & Co. KG, Lübecker Str. 2a, 22926 Ahrensburg, Deutschland. Weitere Details entnehmen Sie bitte deren AGB und Hinweisen zum Datenschutz auf der Website: https://www.cellufine.de</li>
              <li>Oender Kutlu, Kleiderkabine, Unterer Mühlweg 39, 72827 Wannweil, Deutschland. Weitere Details entnehmen Sie bitte deren AGB und Hinweisen zum Datenschutz auf der Website:  https://store.hoehenhorn.de</li>
              <li>Online 4you UG (haftungsbeschränkt), Leopoldstr.2-8, 32051 Herford, Deutschland. Weitere Details entnehmen Sie bitte deren AGB und Hinweisen zum Datenschutz auf der Website: https://www.lizenzexpress.de</li>
              <li>Pro-jex Business GmbH, Friedrichstraße 14, 10969 Berlin, Deutschland. Weitere Details entnehmen Sie bitte deren AGB und Hinweisen zum Datenschutz auf der Website:  https://pro-jex.de</li>
              <li>probierFass, Werner Büchler, Dorfstraße 27A, 86316 Friedberg, Deutschland. Weitere Details entnehmen Sie bitte deren AGB und Hinweisen zum Datenschutz auf der Website:  https://www.probierfass.de</li>
              <li>Puscher Mediatainment, Flughafenstraße 59, 70629 Stuttgart, Deutschland. Weitere Details entnehmen Sie bitte deren AGB und Hinweisen zum Datenschutz auf der Website: https://aktionslicht.de</li>
              <li>Reitstiefel Kandel OHG, Gartenstraße 22, 38723 Seesen, Deutschland. Weitere Details entnehmen Sie bitte deren AGB und Hinweisen zum Datenschutz auf der Website: https://www.reitstiefel-kandel.de</li>
              <li>Robin Look GmbH, Bessemerstraße 82, 12103 Berlin, Deutschland. Weitere Details entnehmen Sie bitte deren AGB und Hinweisen zum Datenschutz auf der Website: https://www.sonnenbrillen-helden.de</li>
              <li>Schneiders Profichemie GmbH & Co. KG, Renntalstraße 8/1, 74360 Ilsfeld, Deutschland. Weitere Details entnehmen Sie bitte deren AGB und Hinweisen zum Datenschutz auf der Website:  https://www.profichemie.com</li>
              <li>SEMPE GmbH, Handwerkerring 1, 39326 Wolmirstedt, Deutschland. Weitere Details entnehmen Sie bitte deren AGB und Hinweisen zum Datenschutz auf der Website: https://www.aufkleberdealer.de</li>
              <li>Simone Nowaczyk Handelsvertrieb, Ulmenstr. 6, 14979 Großbeeren, Deutschland. Weitere Details entnehmen Sie bitte deren AGB und Hinweisen zum Datenschutz auf der Website:  https://humorpharm.com</li>
              <li>Smartwatch.de GmbH, Königsbrücker Str. 61, 01099, Dresden, Deutschland. Weitere Details entnehmen Sie bitte deren AGB und Hinweisen zum Datenschutz auf der Website:  https://www.smartwatch.de</li>
              <li>Thomas Fink, Ernst-Thälmann-Str 3 a, 15562 Rüdersdorf, Deutschland. Weitere Details entnehmen Sie bitte deren AGB und Hinweisen zum Datenschutz auf der Website:  https://www.finktech24.de</li>
              <li>Verena Dielacher (Badfaszination), Jagststraße 9, 70806 Kornwestheim, Deutschland. Weitere Details entnehmen Sie bitte deren AGB und Hinweisen zum Datenschutz auf der Website: https://www.badfaszination.com</li>
              <li>WeKoTrade GmbH, In den Ziegelwiesen 25/1, 69168 Wiesloch, Deutschland. Weitere Details entnehmen Sie bitte deren AGB und Hinweisen zum Datenschutz auf der Website:  https://www.isolier-fuchs.de</li>
              <li>Ziegler & Sohn OHG, Am Hasenbiel 17, 76297 Stutensee, Deutschland. Weitere Details entnehmen Sie bitte deren AGB und Hinweisen zum Datenschutz auf der Website: https://www.cardin-deko.de</li>
            </ul>
          </b-collapse>

        <h2>3. Cookies</h2>
          <p>Auf unserer Seite werden Cookies zum Wiedererkennen mehrfacher Nutzung unserer Webpräsenz durch denselben Nutzer bzw. Internetanschlussinhaber eingesetzt. Dabei handelt es sich um kleine Textdateien, die der Internet-Browser auf Ihrem Rechner ablegt und speichert. Sie dienen der Optimierung unseres Internetauftritts. Überwiegend handelt es sich um Session-Cookies, die nach dem Ende Ihres Besuches wieder gelöscht werden.</p>
          <p>Darüber hinaus werden Cookies verwendet, die anhand von Informationen es ermöglichen, Sie automatisch wieder zu erkennen. Gespeichert wird in den Cookies für diese Wiedererkennung die IP-Adresse. Dies ermöglicht, unsere Angebote zu optimieren und dem Nutzer einen leichteren Zugang auf unsere Seite zu ermöglichen.</p>
          <p>Die Installation der Cookies kann durch eine entsprechende Einstellung des Browsers verhindern werden. In diesem Fall können möglicherweise nicht alle Funktionen unserer Webseite vollumfänglich genutzt werden.</p>
          
          <b-button v-on:click="openCookieWindow()">Datenschutzerklärung akzeptieren</b-button>

        <h2>4. Erhebung und Speicherung personenbezogener Daten sowie Art und Zweck von deren Verwendung</h2>
          <p>Beim Aufrufen unserer Webseite www.xlshoppen.com werden aus technischen Gründen durch den Internet-Browser an uns bzw. an unseren Webspace-Provider Informationen übermittelt bzw. erfasst. Diese Informationen werden temporär in einem sogenannten Logfile gespeichert. Folgende Informationen werden dabei ohne Ihr Zutun erfasst und spätestens nach 9 Wochen gelöscht</p>
          <ul>
            <li>verwendeter Browser und ggf. das Betriebssystem Ihres Rechners sowie der Name Ihres Access-Providers</li>
            <li>Webseite, von der aus der Zugriff erfolgt (Referrer-URL)</li>
            <li>Name und URL der abgerufenen Datei</li>
            <li>Datum und Uhrzeit des Zugriffs</li>
            <li>IP-Adresse des anfragenden Rechners</li>
          </ul>

        <h2>5. Kontaktmöglichkeit</h2>
          <p>Wir bieten Ihnen auf unserer Webseite die Möglichkeit, sich mit uns per E-Mail in Verbindung zu setzen. Soweit Sie uns personenbezogene Daten zur Verfügung stellen, verwenden wir diese nur zur Bearbeitung bzw. Beantwortung Ihrer Anfragen, zur Kontaktaufnahme und für die technische Administration. Eine Weitergabe an Dritte erfolgt nicht. Ein Abgleich dieser Daten mit Daten, die möglicherweise durch andere Komponenten unserer Webseite erhoben werden, erfolgt nicht.</p>
        
        <h2>6. Datensicherheit</h2>
          <p>Wir sind bemüht, personenbezogenen Daten, falls diese bei der Nutzung unserer Website durch Einsatz aller technischen und organisatorischen Möglichkeiten so zu speichern, dass sie für Dritte nicht zugänglich sind. Um die Sicherheit Ihrer Daten bei der Übertragung zu schützen, verwenden wir dem aktuellen Stand der Technik entsprechende Verschlüsselungsverfahren über HTTPS. Bei der Kommunikation per E-Mail kann die vollständige Datensicherheit von uns nicht gewährleistet werden, daher empfehlen wir bei vertraulichen Informationen den Postweg.</p>

        <h2>7. reCAPTCHA</h2>
          <p>Zur Unterbindung von Spam-Mails  ist unsere Website durch reCAPTCHA geschützt und es gelten die <a href="https://policies.google.com/privacy">Datenschutzbestimmungen</a> und <a href="https://policies.google.com/terms">Nutzungsbedingungen</a> von Google.</p>

        <h2>8. Verwendung von Scriptbibliotheken (Google Webfonts)</h2>
          <p>Um eine browserübergreifend korrekte und grafisch ansprechende Darstellung unserer Inhalte sicherzustellen, verwenden wir auf unserer Webseite Scriptbibliotheken und Schriftbibliotheken wie z. B. Google Webfonts. Zur Vermeidung mehrfachen Ladens werden Google Webfonts in den Cache des Browsers übertragen. Sollte der Browser Google Webfonts nicht unterstützt oder der Zugriff unterbunden sein, werden Inhalte in einer Standardschrift angezeigt.</p>
          <p>Mit dem Aufruf einer Scriptbibliothek oder Schriftbibliothek wird automatisch eine Verbindung zum Betreiber der Bibliothek aufgebaut. Dabei ist es möglich, dass der Betreiber einer entsprechenden Bibliotheken Daten erhebt. Die Datenschutzrichtlinie des Bibliothekbetreibers Google finden Sie hier: Datenschutzrichtlinien von Google.</p>

        <h2>9. Betroffenenrechte</h2>
          <p class="font-italic">Sie haben das Recht:</p>
          <ul>
            <li>gemäß Art. 7 Abs. 3 DSGVO Ihre einmal erteilte Einwilligung jederzeit gegenüber uns zu widerrufen. Dies hat zur Folge, dass wir die Datenverarbeitung, die auf dieser Einwilligung beruhte, für die Zukunft nicht mehr fortführen dürfen</li>
            <li>gemäß Art. 15 DSGVO Auskunft über Ihre von uns verarbeiteten personenbezogenen Daten zu verlangen. Insbesondere können Sie Auskunft über die Verarbeitungszwecke, die Kategorie der personenbezogenen Daten, die Kategorien von Empfängern, gegenüber denen Ihre Daten offengelegt wurden oder werden, die geplante Speicherdauer, das Bestehen eines Rechts auf Berichtigung, Löschung, Einschränkung der Verarbeitung oder Widerspruch, das Bestehen eines Beschwerderechts, die Herkunft ihrer Daten, sofern diese nicht bei uns erhoben wurden, sowie über das Bestehen einer automatisierten Entscheidungsfindung einschließlich Profiling und ggf. aussagekräftigen Informationen zu deren Einzelheiten verlangen</li>
            <li>gemäß Art. 16 DSGVO unverzüglich die Berichtigung unrichtiger oder Vervollständigung Ihrer bei uns gespeicherten personenbezogenen Daten zu verlangen</li>
            <li>gemäß Art. 17 DSGVO die Löschung Ihrer bei uns gespeicherten personenbezogenen Daten zu verlangen, soweit nicht die Verarbeitung zur Ausübung des Rechts auf freie Meinungsäußerung und Information, zur Erfüllung einer rechtlichen Verpflichtung, aus Gründen des öffentlichen Interesses oder zur Geltendmachung, Ausübung oder Verteidigung von Rechtsansprüchen erforderlich ist</li>
            <li>gemäß Art. 18 DSGVO die Einschränkung der Verarbeitung Ihrer personenbezogenen Daten zu verlangen, soweit die Richtigkeit der Daten von Ihnen bestritten wird, die Verarbeitung unrechtmäßig ist, Sie aber deren Löschung ablehnen; wir die Daten nicht mehr benötigen, Sie jedoch diese zur Geltendmachung, Ausübung oder Verteidigung von Rechtsansprüchen benötigen oder Sie gemäß Art. 21 DSGVO Widerspruch gegen die Verarbeitung eingelegt haben</li>
            <li>gemäß Art. 20 DSGVO Ihre personenbezogenen Daten, die Sie uns bereitgestellt haben, in einem strukturierten, gängigen und maschinenlesebaren Format zu erhalten oder die Übermittlung an einen anderen Verantwortlichen zu verlangen</li>
            <li>gemäß Art. 77 DSGVO sich bei einer Aufsichtsbehörde zu beschweren. In der Regel können Sie sich hierfür an die Aufsichtsbehörde ihres üblichen Aufenthaltsortes oder Arbeitsplatzes oder unseres Unternehmenssitzes wenden.</li>
          </ul>

        <h2>10. Aktualität und Änderung unserer Datenschutzbestimmungen</h2>
          <p class="mb-3 mb-md-5">Diese Datenschutzerklärung ist aktuell gültig und hat den Stand September 2020. Wir behalten uns vor, diese Datenschutzerklärung anzupassen, damit sie stets den aktuellen rechtlichen Anforderungen entspricht. Die jeweils aktuelle Datenschutzerklärung kann jederzeit auf der Webseite unter der URL www.xlshoppen.com abgerufen und ausgedruckt werden. Für einen Besuch unserer Webseite gilt die jeweils gültige Datenschutzerklärung.</p>
    </div>
  </div>
</template>

<script>
export default {
  methods:{
    openCookieWindow(){
       this.$root.$children[0].showCookieWindow();
    }
  }
};
</script>


<style scoped>
@import "../css/heading.css";
h2 {
  font-size: 1.2rem;
  font-weight: bold;
  margin-top: 1.5rem;
}
h3 {
  font-size: 1.1em;
  font-weight: bold;
}

a {
  color: #848484;
  text-decoration: underline;
}

li {
  padding-bottom: 0.5rem;
}
</style>
