import Vue from 'vue'
import VueRouter from 'vue-router'

import StartPage from '@/components/StartPage'
import Results from '@/components/Results'
import Impressum from '@/components/Impressum'
import DSGVO from '@/components/DSGVO'
import Cookie from '@/components/Cookie'

Vue.use(VueRouter)

const router = new VueRouter({
    mode: 'history',
    routes: [
        {
            path: '/',
            name: 'Startpage',
            component: StartPage
        },
        {
            path: '/impressum',
            name: 'Impressum',
            component: Impressum
        },
        {
            path: '/dsgvo',
            name: 'DSGVO',
            component: DSGVO
        },
        {
            path: '/cookie',
            name: 'Cookie',
            component: Cookie
        },
        {
            path: '/*',
            name: 'Results',
            component: Results
        },
    ]
});

router.beforeEach((to, from, next) => {
    // Redirect if fullPath begins with a hash (ignore hashes later in path)
    if (to.fullPath.substr(0, 2) === "/#") {
        const path = to.fullPath.substr(2);
        next(path);
    }
    else {
        next();
    }
});

export default router;