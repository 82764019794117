import Store from '../store';

export default class UrlBuilder {

    constructor() {
        this.urlSettings = ["category", "filters", "page", "query"];
    }

    buildUrl(filters, pageNumber) {
        this.urlSettings["category"] = true;
        this.urlSettings["filters"] = true;
        this.urlSettings["page"] = true;
        this.urlSettings["query"] = true;
        const categorySlug = this.getActiveCategory(filters.activeCategory);
        return this.build(categorySlug, filters, pageNumber);
    }

    buildUrlForCategoryItem(categorySlug) {
        if (categorySlug === "") {
            this.urlSettings["category"] = false;
            this.urlSettings["filters"] = false;
            this.urlSettings["page"] = true;
            this.urlSettings["query"] = false;
        } else {
            this.urlSettings["category"] = true;
            this.urlSettings["filters"] = false;
            this.urlSettings["page"] = false;
            this.urlSettings["query"] = false;
        }
        return this.build(categorySlug, "", 1);
    }

    build(categoryUrlPart, filters, pageNumber) {

        let url = "";
        if (this.urlSettings["category"]) {
            if (categoryUrlPart === "/") {
                categoryUrlPart = "";
            } else {
                url += categoryUrlPart;
            }

        }
        if (this.urlSettings["filters"]) {
            url += this.getFilter(filters.brands, filters.providers);
        }
        if (this.urlSettings["page"]) {
            let filterUrlPart = "";
            if (filters !== "") {
                filterUrlPart = this.getFilter(filters.brands, filters.providers);
            }
            url += this.getPageUrlPart(categoryUrlPart, filterUrlPart, pageNumber);
        }
        if (this.urlSettings["query"]) {
            url += this.getQuery(filters.searchBar, filters.minPrice, filters.options.minPrice, filters.maxPrice, filters.options.maxPrice);
        }
        if (url.charAt(0) !== "/") {
            url = "/" + url
        }
        return url;
    }

    getActiveCategory(activeCategory) {
        const catElement = Store.state.categories.find(element => element.id === activeCategory);
        return catElement === undefined ? "" : "/" + catElement.slug;
    }

    getFilter(brands, providers) {
        const b = this.encodeFilter(brands, "m");
        const p = this.encodeFilter(providers, "p");
        const bp = b + p;
        return bp !== "" ? "-" + bp : "";
    }

    getPageUrlPart(categoryUrlPart, filterUrlPart, pageNumber) {
        let result = "";
        pageNumber = parseInt(pageNumber);

        if (categoryUrlPart === "" && filterUrlPart === "" || pageNumber !== 1) {
            result = "/" + pageNumber + ".html";
        }

        return result;
    }

    getQuery(searchBar, minPrice, defaultMinPrice, maxPrice, defaultMaxPrice) {
        let result = "";

        const search = searchBar !== "" ? "s=" + searchBar : "";
        let min = this.getPrice(minPrice, defaultMinPrice);
        if (minPrice < defaultMinPrice) {
            min = "";
        }
        let max = this.getPrice(maxPrice, defaultMaxPrice);
        if (maxPrice > defaultMaxPrice) {
            max = "";
        }

        let price = this.getPriceUrlPart(search, min, defaultMinPrice, max, defaultMaxPrice);
        if (search !== "" || price !== "") {
            result = "?" + search + price;
        }
        return result;
    }

    getPrice(price, defaultPrice) {
        let result = "";
        if (defaultPrice !== price) {
            result = price;
        }
        return result;
    }

    getPriceUrlPart(searchBar, minPrice, defaultMinPrice, maxPrice, defaultMaxPrice) {
        let result = "";
        if (minPrice !== "" || maxPrice !== "") {
            const priceOperator = searchBar === "" ? "p=" : "&p=";
            const min = minPrice !== "" ? minPrice : defaultMinPrice;
            const max = maxPrice !== "" ? maxPrice : defaultMaxPrice;
            result = priceOperator + min + "-" + max;
        }
        return result;

    }

    encodeFilter(ids, abbreviation) {
        let result = "";

        if (ids.length > 0) {
            ids.forEach(element => {
                const hex = Number(element).toString(16);
                result += abbreviation + hex;
            });
        }
        return result;
    }
}