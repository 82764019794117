<template>
  <div class="container">
    <div id="heading-mobile" class="d-flex justify-content-between w-100 mt-3">
      <h4 class="d-block d-md-none" :class="hideDefaultName">{{ category }}</h4>
      <h4 id="startpoint" class="d-block d-md-none text-right pr-0">
        {{ this.$store.state.totalResults }} Produkte
      </h4>
    </div>

    <div class="row">
      <div class="col-md-4 col-lg-3">
        <FilterArea />
      </div>
      <div id="product-results-area" class="col-md-8 col-lg-9">
        <div v-if="this.$store.state.products !== null">
          <div class="row align-items-end justify-content-between mt-3 mt-md-4">
            <h4 id="startpoint" class="col-md-3 d-none d-md-inline-block pb-2">
              {{ this.$store.state.totalResults }} Produkte
            </h4>
            <SortResults class="col-md-9" />
          </div>
          <Products />
        </div>
        <div v-else class="mx-auto mt-5 loader">
          <moon-loader :size="size" :color="color"></moon-loader>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import FilterArea from "./FilterArea.vue";
import SortResults from "./SortResults";
import Products from "./Products.vue";
import MoonLoader from "vue-spinner/src/MoonLoader.vue";

export default {
  name: "Results",
  components: {
    FilterArea,
    SortResults,
    Products,
    MoonLoader,
  },
  data() {
    return {
      size: "150px",
      color: "#ff9235",
    };
  },
  computed: {
    category: function () {
      return this.$store.getters.getActiveCategoryName;
    },
    hideDefaultName() {
      return {
        hideCat: this.category == "Kategorie wählen",
      };
    },
  },
};
</script>

<style scoped>
#startpoint {
  color: #848484;
}
#heading-mobile {
  font-size: 0.7em;
}
.hideCat {
  visibility: hidden;
}
.loader {
  max-width: 150px;
}
@media screen and (max-width: 992px) and (min-width: 768px) {
  #startpoint {
    font-size: 1em;
    padding-bottom: 9px !important;
  }
}
</style>