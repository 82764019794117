import Store from '../store';

export default {
    readUrl(path, query) {
        let urlParts = this.cutInUrlParts(path);
        let activeCategory = this.getCategory(urlParts["categoryPart"]);
        let brands = this.getNames(urlParts["brandPart"], "m");
        let providers = this.getNames(urlParts["providerPart"], "p");
        let page = this.isNormalInteger(urlParts["pagePart"]) ? urlParts["pagePart"] : 1;
        let search = query.s ?? "";
        let minPrice = this.getMinPrice(query.p ?? "");
        let maxPrice = this.getMaxPrice(query.p ?? "");

        return {
            "activeCategory": activeCategory,
            "brands": brands,
            "providers": providers,
            "page": page,
            "search": search,
            "minPrice": minPrice,
            "maxPrice": maxPrice,
        };
    },
    cutInUrlParts(path) {
        let categoryPart = "";
        let providerPart = "";
        let brandPart = "";
        let pagePart = "";

        let hasBrandsAndProviders = false;

        //Categories
        let catStart = 1;
        let catEnd = path.indexOf('-', catStart);
        if (catEnd === -1) {
            catEnd = path.indexOf('/', catStart);
            if (catEnd === -1 && path.indexOf('.html', catStart) === -1) {
                catEnd = path.length;
            }
        } else {
            hasBrandsAndProviders = true;
        }

        if (catEnd > 1) {
            categoryPart = path.substring(catStart, catEnd);
            path = path.substring(catEnd);
        }

        // Brands/Providers
        if (hasBrandsAndProviders) {
            const bpStart = path.indexOf('-') + 1;
            let bpEnd = path.indexOf('/', bpStart);
            if (bpEnd - 1) {
                bpEnd = path.length;
            }
            const brandproviderPart = path.substring(bpStart, bpEnd);
            brandPart = brandproviderPart.indexOf('p') === -1 ? brandproviderPart : brandproviderPart.substring(0, brandproviderPart.indexOf('p'));
            providerPart = brandproviderPart.substring(brandPart.length);
            path = path.substring(brandproviderPart.length + 1);
        }

        // Page
        if (path.indexOf('/') === 0) {
            pagePart = path.substring(1, path.indexOf('.html'));
        }

        return {
            "categoryPart": categoryPart,
            "brandPart": brandPart,
            "providerPart": providerPart,
            "pagePart": pagePart,
        };
    },
    getCategory(categoryPart) {
        const activeCategory = Store.state.categories.find(element => element.slug === categoryPart);
        return activeCategory !== undefined ? activeCategory.id : "";
    },
    getNames(urlPart, startChar) {
        let starCharPos = this.getAllIndexes(urlPart, startChar);
        let arr = [];
        for (let i = 0; i < starCharPos.length; i++) {
            const start = starCharPos[i] + 1;
            const end = i === starCharPos.length - 1 ? urlPart.length : starCharPos[i + 1];
            const hex = urlPart.substring(start, end);
            const digit = parseInt(Number("0x" + hex), 10)
            arr.push(digit.toString());
        }
        return arr;
    },
    getMinPrice(query) {
        if (query === "") {
            return Store.state.searchFilters.minPrice;
        }
        const endChar = '-';
        const posEnd = query.indexOf(endChar);
        return query.substring(0, posEnd);
    },
    getMaxPrice(query) {
        if (query === "") {
            return Store.state.searchFilters.maxPrice;
        }
        const startChar = '-';
        const posStart = query.indexOf(startChar) + 1;
        return query.substring(posStart);
    },
    getAllIndexes(arr, char) {
        var indexes = [];
        for (let i = 0; i < arr.length; i++) {
            if (arr[i] === char) {
                indexes.push(i);
            }
        }
        return indexes;
    },
    isNormalInteger(str) {
        return /^\+?(0|[1-9]\d*)$/.test(str);
    }
}
