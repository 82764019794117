<template>
  <div id="header" class="container.fluid">
    <HeaderGraphic />
    <div class="row" id="headerBar">
      <div id="trapezoid">
        <div id="menu-button" class="ml-3">
          <ChooseCat />
        </div>
      </div>
      <div id="search-bar-area" class="col align-self-center">
        <Search id="searchBar" />
      </div>
    </div>
  </div>
</template>

<script>
import HeaderGraphic from "./HeaderGraphic.vue";
import ChooseCat from "./ChooseCat.vue";
import Search from "./Search.vue";

export default {
  name: "Header",
  components: {
    HeaderGraphic,
    ChooseCat,
    Search,
  },
};
</script>

<style scoped>
#headerBar {
  background-color: #ff9235;
  height: 4rem;
}
@media screen and (min-width: 768px) {
  #headerBar{
    height: 5.5rem;
  }
}

#trapezoid {
  height: 100%;
  width: 34%;
  max-width: 400px;
  position: relative;
  background: url("../assets/navi-bar.png") no-repeat right;
  background-size: auto 100%;
}
@media screen and (max-width: 768px) {
  #trapezoid {
    min-width: 140px;
    max-width: 200px;
    padding-right: 0;
  }
  #search-bar-area {
    padding-left: 0;
  }
  #menu-button {
    width: 100vw;
  }
}
#menu-button {
  display: inline-block;
  position: absolute;
  z-index: 0;
}

#navtitle {
  color: lightgray;
  font-size: 1rem;
  padding: 1rem 4rem;
}
</style>