import Store from '../store';
import SortFields from "@/sorting/sortFields";
import Helpers from "../helpers/helpers";

const PRODUCTS_API_PATH = "products";
const CATEGORIES_API_PATH = "categories";
const FILTERS_API_PATH = "filters/";

export default {
  categoryRequest(callbackFunction) {
    this.request(
      CATEGORIES_API_PATH,
      (value) => this.handleCategoryResponse(value, callbackFunction),
      (value) => this.handleCategoryRevocation(value),
      {}
    );
  },
  categoryIdRequest(id) {
    let params = {
      'filter[parentId]': id
    };
    this.request(
      CATEGORIES_API_PATH,
      (value) => this.handleProductResponse(value),
      (value) => this.handleProductRevocation(value),
      params
    );
  },
  productRequest() {
    Store.commit('changeProducts', null);
    let filters = Store.getters.getSearchFilters;
    let sorting = Store.getters.getSorting;
    let pagination = Store.getters.getPagination;

    let params = {
      'filter[name]': filters.searchBar,
      'filter[sortCriterion]': this.getSortingCriterion(sorting.criterion),
      'filter[sortOrder]': sorting.orderIsAsc ? "asc" : "desc",
      'page[number]': pagination.page,
      'page[size]': pagination.size,
      "filter[providers]": filters.providers.toString(),
      "filter[brands]": filters.brands.toString(),
      "filter[minPrice]": filters.minPrice * 100,
      "filter[maxPrice]": filters.maxPrice * 100,
      "filter[categories]": filters.activeCategory
    }

    this.request(
      PRODUCTS_API_PATH,
      (value) => this.handleProductResponse(value),
      (value) => this.handleProductRevocation(value),
      params
    );
  },
  getSortingCriterion(criterion) {
    let result = "";
    switch (criterion) {
      case SortFields.PRICE:
        result = "price";
        break;
      case SortFields.NAME:
        result = "name";
        break;
      case SortFields.BRAND:
        result = "brands";
        break;
      case SortFields.PROVIDER:
        result = "providers";
        break;
    }
    return result;
  },
  filterRequest(callbackFunction) {
    Store.commit('changeFilterProviders', null);
    Store.commit('changeFilterBrands', null);
    let filters = Store.getters.getSearchFilters;

    let params = {
      "filter[categories]": filters.activeCategory
    }

    let searchValue = filters.searchBar != "" ? filters.searchBar : "__null__";
    this.request(
      FILTERS_API_PATH + searchValue,
      (value) => this.handleFilterOptionsResponse(value, callbackFunction),
      (value) => this.handleFilterOptionsRevocation(value),
      params
    );
  },
  async request(path, callBackFunction, revocationFunction, params) {
    try {
      let response = await Store.dispatch('jv/get', [path, { params: params }]);
      if (Object.keys(response).length > 1) {
        callBackFunction(response);
      } else {
        revocationFunction();
      }
    } catch (e) {
      console.log(e);
      revocationFunction();
    }
  },
  handleProductResponse(response) {
    Store.commit('changeProducts', Helpers.mapToArr(response));
    Store.commit('changePaginationTotalPages', response._jv.json.meta.totalPages);
    Store.commit('changeTotalResults', response._jv.json.meta.totalResults);
  },
  handleProductRevocation() {
    Store.commit('changeProducts', []);
    Store.commit('changePaginationTotalPages', 1);
    Store.commit('changePaginationPage', 1);
    Store.commit('changeTotalResults', 0);
  },
  handleFilterOptionsResponse(response, callbackFunction) {
    let providers = response.providers;
    let brands = response.brands;
    Store.commit('changeFilterMinPrice', response.minPrice / 100);
    Store.commit('changeFilterMaxPrice', response.maxPrice / 100);
    Store.commit('changeFilterProviders', Helpers.mapFilterObjToArr(providers));
    Store.commit('changeFilterBrands', Helpers.mapFilterObjToArr(brands));
    
    if (callbackFunction !== undefined) {
      callbackFunction();
    }
  },
  handleFilterOptionsRevocation() {
    Store.commit('changeFilterMinPrice', 0);
    Store.commit('changeFilterMaxPrice', 0);
    Store.commit('changeFilterProviders', []);
    Store.commit('changeFilterBrands', []);
  },
  handleCategoryResponse(response, callbackFunction) {
    let arr = [];
    arr.push({
      name: "Alle Kategorien",
      id: -1,
      slug: "",
      href: ""
    });
    Object.keys(response).forEach((key) => {
      arr.push({
        name: response[key].name,
        id: key,
        slug: response[key].slug,
        href: ""
      });
    });

    Store.commit("changeCategories", arr);

    if (callbackFunction !== undefined) {
      callbackFunction();
    }
  },
  handleCategoryRevocation() {
    Store.commit('changeCategories', "");
  },
};