import Vue from 'vue';
import BootstrapVue from 'bootstrap-vue';
import VueCookies from 'vue-cookies';

import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-vue/dist/bootstrap-vue.css';

import App from './App.vue';
import router from './router';
import store from "./store";

Vue.use(BootstrapVue);
Vue.use(VueCookies);

Vue.config.productionTip = false;
document.title = "XL SHOPPEN";

window.confApp = new Vue({
  render: h => h(App),
  router,
  store
}).$mount('#app');