<template>
  <div id="header-graphic" class="row">
    <router-link :to="'/'" id="logo-link">
      <img id="logo" src="../assets/logo_xl_shoppen.jpg"  @click="reset" />
    </router-link>
  </div>
</template>

<script>
export default {
  data() {
    return {
      currentPage: this.$store.state.pagination.page,
    };
  },
  methods: {
    reset() {
    this.currentPage = 1;
    this.updatePage();
  },
    updatePage() {
      this.$store.commit("changePaginationPage", this.currentPage);
    },
  }
}
</script>

<style scoped>
#header-graphic {
  background: url("../assets/head.png") no-repeat;
  background-size: cover;
  height: 8.5rem;
}
#logo-link {
  display: inline-block;
  margin-left: 8%;
  max-height: 100%;
}
#logo {
  display: inline-block;
  height: 100%;
  width: auto;
}
@media screen and (min-width: 768px) {
  #header-graphic {
    height: 9rem;
  }
}
@media screen and (max-width: 576px) {
  #header-graphic {
    background: url("../assets/head-phone.png") no-repeat;
    background-size: cover;
    height: 8rem;
  }
  #logo-link {
    margin: auto;
  }
  #logo {
    height: 7rem;
  }
}
</style>