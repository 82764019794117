<template>
  <div id="container" class="container mt-md-4">
    <div class="greyline ml-5"></div>
    <div class="heading-bw ml-2 mb-md-4">
      <h4 class="distance1">Beliebte Kategorien</h4>
    </div>
    <FavCats />
    <div class="greyline ml-5 mt-5"></div>
    <div class="heading-bw ml-2">
      <h4 class="mt-3 mt-md-0 distance1">Beliebte Produkte</h4>
    </div>
    <div v-if="this.$store.state.products !== null">
      <FavProducts />
    </div>
    <div v-else class="mx-auto mt-5 loader">
      <moon-loader :size="size" :color="color"></moon-loader>
    </div>
  </div>
</template>

<script>
import FavCats from "./FavCats.vue";
import FavProducts from "./Products.vue";
import ApiRequest from "@/api/apiRequest";
import MoonLoader from "vue-spinner/src/MoonLoader.vue";

export default {
  name: "StartPage",
  components: {
    FavCats,
    FavProducts,
    MoonLoader,
  },
  data() {
    return {
      size: "150px",
      color: "#ff9235",
    };
  },
  mounted: function () {
    this.$nextTick(function () {
      // Will be executed when the DOM is ready

      this.$store.commit("changeSearchName", "");
      this.$store.commit("changeActiveCategory", "");
      this.$store.commit("changeProducts", null);
      ApiRequest.productRequest();
    });
  },
};
</script>

<style scoped>
@import "../css/heading.css";
#container {
  padding-top: 30px;
}
.loader {
  max-width: 150px;
}
</style>