<template>
  <div id="products" class="container w-100">
    <div id="products-row" class="row">
      <div
        id="product"
        v-for="(product, index) in sortedProducts"
        :key="index"
        class="col-6 col-sm-4 col-lg-3 col-xl-2"
      >
        <a :href="product.shopUrl" target="_blank">
          <div
            id="products-space"
            class="d-flex flex-column justify-content-between"
          >
            <img
              :src="product.imageUrl"
              @error="replaceByDefault"
              class="d-block mx-auto"
            />

            <div>
              <p id="priceline">
                ab
                <span class="price" id="priceEuro">{{
                  getEuroPartFromPrice(product.deliveryPrice)
                }}</span>
                <span class="price">,</span>
                <span class="price" id="priceCent">{{
                  getCentPartFromPrice(product.deliveryPrice)
                }}</span>
                <span class="price">€<sup>*</sup></span>
              </p>
              <p id="product-name">{{ product.name }}</p>
              <p id="category">{{ product.category.name }}</p>
              <p id="shop-link">{{ product.provider.name }}</p>
            </div>
          </div>
        </a>
      </div>

      <div
        v-if="Object.keys(this.$store.state.products).length > 0"
        id="pagination"
        class="mx-auto"
      >
        <div v-if="this.$router.history.current.name !== 'Startpage'">
          <b-pagination-nav
            v-model="currentPage"
            :number-of-pages="this.$store.state.pagination.totalPages"
            use-router
            :link-gen="paginationHrefBuilder"
            first-number
            last-number
            v-on:input="apiRequest()"
            :per-page="this.$store.state.pagination.size"
            class="orange-numbers"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ApiRequest from "@/api/apiRequest";
import SortFields from "@/sorting/sortFields";
import defaultImg from "@/assets/icon_no_picture.png";

export default {
  data() {
    return {
      currentPage: this.$store.state.pagination.page,
    };
  },
  mounted() {
    this.$root.$on("page-event", this.handlePageEvent);
  },
  methods: {
    replaceByDefault(e) {
      e.target.src = defaultImg;
      e.target.classList.remove("mx-auto");
      e.target.classList.add("defaultImg");
    },
    apiRequest() {
      this.updatePage();
      ApiRequest.productRequest();
      this.$root.$children[0].showCookieWindow();

      this.$root.$children[0].routerPush();
    },
    reset() {
      this.currentPage = 1;
      this.updatePage();
    },
    updatePage() {
      this.$store.commit("changePaginationPage", this.currentPage);
      this.$root.$children[0].goToTop();
    },
    calculateNumberOfResults() {
      this.$store.state.pagination.page;
    },
    getEuroPartFromPrice(value) {
      let valueStr = value + "";
      valueStr = valueStr.substr(0, valueStr.length - 2);
      return valueStr ? valueStr : 0;
    },
    getCentPartFromPrice(value) {
      let valueStr = value + "";
      return valueStr.substr(valueStr.length - 2, valueStr.length);
    },
    handlePageEvent() {
      this.reset();
    },
    orderedByName(toSort, sortValue1, sortValue2) {
      return toSort.sort((a, b) =>
        a.name.toLowerCase() > b.name.toLowerCase() ? sortValue1 : sortValue2
      );
    },
    orderedByPrice(toSort, sortValue1, sortValue2) {
      return toSort.sort((a, b) =>
        a.deliveryPrice > b.deliveryPrice ? sortValue1 : sortValue2
      );
    },
    orderedByBrand(toSort, sortValue1, sortValue2) {
      return toSort.sort((a, b) =>
        a.brand.name.toLowerCase() > b.brand.name.toLowerCase()
          ? sortValue1
          : sortValue2
      );
    },
    orderedByProvider(toSort, sortValue1, sortValue2) {
      return toSort.sort((a, b) =>
        a.provider.name.toLowerCase() > b.provider.name.toLowerCase()
          ? sortValue1
          : sortValue2
      );
    },
    paginationHrefBuilder(pageNumber) {
      let urlBuilder = this.$root.$children[0].getUrlBuilder();
      return urlBuilder.buildUrl(this.$store.state.searchFilters, pageNumber);
    },
  },
  computed: {
    sortedProducts: function () {
      if (
        this.$store.state.products === null ||
        this.$store.state.products === []
      ) {
        return null;
      }
      let products, sortValue1, sortValue2;

      if (this.$store.state.sorting.orderIsAsc) {
        sortValue1 = 1;
        sortValue2 = -1;
      } else {
        sortValue1 = -1;
        sortValue2 = 1;
      }

      switch (this.$store.state.sorting.criterion) {
        case SortFields.NAME:
          products = this.orderedByName(
            this.$store.state.products,
            sortValue1,
            sortValue2
          );
          break;
        case SortFields.PRICE:
          products = this.orderedByPrice(
            this.$store.state.products,
            sortValue1,
            sortValue2
          );
          break;
        case SortFields.BRAND:
          products = this.orderedByBrand(
            this.$store.state.products,
            sortValue1,
            sortValue2
          );
          break;
        case SortFields.PROVIDER:
          products = this.orderedByProvider(
            this.$store.state.products,
            sortValue1,
            sortValue2
          );
          break;
      }
      return products;
    },
  },
};
</script>

<style scoped>
#products-row {
  max-width: 100vw;
}
h4 {
  margin-top: 1rem;
  margin-bottom: 0.5rem;
  color: #848484;
}
a {
  color: inherit;
  text-decoration: none;
}
p {
  margin-bottom: 0.15rem;
  line-height: 1;
}
#product {
  padding: 0.3rem;
  font-family: "Roboto";
}
#products-space {
  padding: 0.6rem;
  height: 100%;
}
#products img {
  max-width: 100%;
  max-height: 8rem;
  height: auto;
  margin-bottom: 1rem;
}
#priceline {
  color: #6f7374;
}
#priceline span {
  color: #ff9235;
  font-weight: bold;
  text-shadow: 0px 0px, 0px 0px, 1px 0px;
}
.price {
  font-size: 1.5em;
}
#priceCent {
  position: relative;
  top: -0.2em;
  font-size: 1.2em;
}
#product-name {
  font-size: 0.9rem;
  font-weight: bold;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  vertical-align: super;
  padding-top: 3px;
}
#category {
  font-size: 80%;
  color: #848484;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding-top: 3px;
}
#shop-link {
  color: #848484;
  font-size: 0.8em;
  text-decoration: underline;
  display: inline-block;
  padding-top: 0.2rem;
}
.defaultImg {
  padding: 1em 1em 1em 1em;
}
@media screen and (max-width: 768px) {
  #products-space {
    border: 1px solid #bbbbbb;
  }
}
@media screen and (min-width: 768px) {
  #products-space {
    background: white;
  }
}

#pagination {
  padding-top: 1rem;
  padding-bottom: 1.5rem;
}
</style>