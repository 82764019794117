<template>
  <footer class="footer mt-auto">
    <b-container class="container px-5">
      <b-row>
        <b-col id="footerlinks" sm="3">
          <p>
            <router-link class="footerlink" :to="{ name: 'Impressum'}">Impressum</router-link>
          </p>
          <p>
            <router-link class="footerlink" :to="{ name: 'DSGVO'}">Datenschutz</router-link>
          </p>
        </b-col>
        <b-col id="disclaimer" sm="9">
          <p class="small">
            *Alle Preise in Euro inkl. gesetzl. MwSt. ggf. zzgl. Versandkosten und ggf. Abholpreis beim Händler erfragen.
            Die angezeigten Preise können sich seit der letzten Aktualisierung beim jeweiligen Händler geändert haben.
          </p>
        </b-col>
      </b-row>
    </b-container>
  </footer>
</template>


<script>
export default {
  name: "Footer",
  components: {},
};
</script>

<style>
.footerlink,
.footerlink:hover {
  color: #878787;
  text-decoration: underline;
}
.footer {
  margin-left: -15px;
  margin-right: -15px;
  padding: 2rem 1rem 1rem;
  color: #878787;
  text-align: center;
  font-family: 'Roboto';
  background: #1d1d1d;
}
@media screen and (min-width: 576px) {
  .footer {
    text-align: left;
  }
}
@media screen and (min-width: 992px) {
  #disclaimer p {
    font-size: 1rem;
  }
}
</style>