
<template>
  <div id="searchform">
    <b-form-input
      id="search-input"
      size="sm"
      placeholder="Wonach suchen Sie?"
      v-model="$store.state.searchFilters.searchBar"
      @keyup.enter="searchFunc"
      type="text"
      class="d-inline-block"
    ></b-form-input>
    <b-button
      id="searchButton"
      size="sm"
      class="d-none d-md-inline-block my-sm-0 px-5"
      v-on:click="searchFunc"
      >Finden</b-button
    >
    <b-button
      id="searchButton-small"
      size="sm"
      class="d-md-none my-2 my-sm-0"
      v-on:click="searchFunc"
    >
      <img src="../assets/Lupe.png" />
    </b-button>
  </div>
</template>

<script>
import ApiRequest from "@/api/apiRequest";

export default {
  methods: {
    searchFunc() {
      this.resetStoreValues();
      ApiRequest.productRequest();
      ApiRequest.filterRequest();

      this.$root.$children[0].routerPush();
      this.$root.$children[0].setCanonicalUrl();
    },
    resetStoreValues() {
      this.$root.$emit("page-event");
      this.$store.commit("changeFilterMinPrice", 0);
      this.$store.commit("changeFilterMaxPrice", 1000000);
      this.$store.commit("changeFilterProviders", null);
      this.$store.commit("changeFilterBrands", null);
    },
  },
};
</script>

<style scoped>

#search-input {
  width: 69%;
  max-width: 500px;
  border-radius: 0;
  border: 1px solid #000000;
}
#searchButton {
  height: 45px;
  background-color: #000000;
  border-radius: 0;
  font-size: 1.2rem;
  font-weight: bold;
  border: 1px solid #000000;
  margin-bottom: 3px !important;
}
#searchButton-small {
  border-radius: 0;
  max-height: 90%;
  padding: 0;
  border: none;
  position: relative;
  right: 1px;
  bottom: 1px;
}
#searchButton-small img {
  height: 2rem;
}
@media screen and (min-width: 768px) {
  #search-input {
    height: 45px;
    font-size: 1.2rem;
  }
}
</style>