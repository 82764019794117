<template>
  <div>
    <b-modal
      id="modal-1"
      title="Cookies & Datenschutz"
      ref="modal"
      ok-title="Einverstanden"
      @ok="setBannerCookie()"
      cancel-title="Nicht Einverstanden"
      ok-only
      no-close-on-esc
      hide-header-close
      modal-backdrop="false"
      no-close-on-backdrop
    >
      <p class="my-4">{{ settings.infoTextContent }}</p>
      <p class="my-4">
        {{ settings.infoLinkContent }}
        <router-link to="dsgvo" v-on:click.native="close()">Datenschutz</router-link>
      </p>
    </b-modal>
  </div>
</template>

<script>
export default {
  data() {
    return {
      settings: {
        infoTextContent:
          "Diese Webseite verwendet Cookies. Mit der Nutzung von www.xlshoppen.com erklären Sie sich damit einverstanden, dass wir Cookies verwenden. ",
        infoLinkContent: "Mehr Informationen unter ",
        cookieName: "FV_COOKIES_ACCEPTED",
        cookieDays: 90,
      },
    };
  },
  methods: {
    show() {
      if (!this.existBannerCookie()) {
        this.$refs.modal.show();
      }
    },
    close() {
      this.$refs.modal.hide();
    },
    existBannerCookie() {
      return window.$cookies.isKey(this.settings.cookieName);
    },
    setBannerCookie() {
      var expireDate = new Date(
        new Date().getTime() + 86400000 * this.settings.cookieDays
      ).toUTCString();
      window.$cookies.set(
        this.settings.cookieName,
        this.settings.cookieName + "=true; path=/; expires=" + expireDate,
        expireDate
      );
    },
  },
};
</script>