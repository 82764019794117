import Others from '@/assets/category_others.png';
import Family from '@/assets/category_family_children.png';
import HouseLiving from '@/assets/category_house_living.png';
import ClothingFashion from '@/assets/category_clothing_fashion.png';
import Technology from '@/assets/category_technology.png';
import HealthCare from '@/assets/category_health_care.png';
import LeisureGarden from '@/assets/category_leisure_garden.png';

const _lookupTable = {
    "Technik": Technology,
    "Haus & Wohnen": HouseLiving,
    "Kleidung & Mode": ClothingFashion,
    "Familie & Kinder": Family,
    "Freizeit & Garten": LeisureGarden,
    "Gesundheit & Pflege": HealthCare
};

export default function getImg(categoryName) {
    if (typeof _lookupTable[categoryName] !== 'undefined') {
        return _lookupTable[categoryName];
    }

    return Others;
}