import Vue from "vue";
import Vuex from "vuex";
import { jsonapiModule } from 'jsonapi-vuex';
import axios from 'axios';

import config from '@/api/vuexJsonApiConfig';
import SortFields from "@/sorting/sortFields";

Vue.use(Vuex);

const api = axios.create({
  baseURL: process.env.VUE_APP_APIURL,
  withCredentials: false,
  headers: {
    'Content-Type': 'application/vnd.api+json',
    Accept: 'application/vnd.api+json',
  },
});

export default new Vuex.Store({
  state: {
    searchFilters: {
      searchBar: '',
      providers: [],
      brands: [],
      minPrice: 0,
      maxPrice: 1000000,
      activeCategory: "",
      options: {
        minPrice: 0,
        maxPrice: 1000000,
        providers: null,
        brands: null
      }
    },
    sorting: {
      criterion: SortFields.NAME,
      orderIsAsc: true
    },
    products: null,
    pagination: {
      page: 1,
      size: 24,
      totalPages: 1,
    },
    totalResults: 0,
    categories: [],
  },
  getters: {
    getSearchFilters: state => {
      return state.searchFilters;
    },
    getSorting: state => {
      return state.sorting;
    },
    getPagination: state => {
      return state.pagination;
    },
    getActiveCategoryName: state => {
      let catName = "Kategorie wählen";
      let categories = state.categories;
      let activeCategory = state.searchFilters.activeCategory;

      Object.keys(categories).forEach((key) => {

        if (activeCategory === categories[key].id) {
          catName = categories[key].name;
        }
      });

      return catName;
    }
  },
  mutations: {
    changeSearchName(state, payload) {
      state.searchFilters.searchBar = payload;
    },
    changeProducts(state, payload) {
      state.products = payload;
    },
    changePaginationPage(state, payload) {
      state.pagination.page = payload;
    },
    changePaginationTotalPages(state, payload) {
      state.pagination.totalPages = payload;
      if(state.pagination.page > payload){
        state.pagination.page = 1;
      }
    },
    changeTotalResults(state, payload) {
      state.totalResults = payload;
    },
    changeFilterMinPrice(state, payload) {
      state.searchFilters.options.minPrice = payload;
      state.searchFilters.minPrice = payload;
    },
    changeFilterMaxPrice(state, payload) {
      state.searchFilters.options.maxPrice = payload;
      state.searchFilters.maxPrice = payload;
    },
    resetMinPrice(state, payload) {
      state.searchFilters.minPrice = payload;
    },
    resetMaxPrice(state, payload) {
      state.searchFilters.maxPrice = payload;
    },
    changeFilterProviders(state, payload) {
      state.searchFilters.options.providers = payload;
    },
    changeFilterBrands(state, payload) {
      state.searchFilters.options.brands = payload;
    },
    changeProviders(state, payload) {
      state.searchFilters.providers = payload;
      if (payload.length == 0) {
        state.searchFilters.providers = [];
      }
    },
    changeBrands(state, payload) {
      state.searchFilters.brands = payload;
      if (payload.length == 0) {
        state.searchFilters.brands = [];
      }
    },
    changeSortingCriterion(state, payload) {
      state.sorting.criterion = payload;
    },
    changeOrderIsASC(state, payload) {
      state.sorting.orderIsAsc = payload;
    },
    changeCategories(state, payload) {
      state.categories = payload;
    },
    changeActiveCategory(state, payload) {
      state.searchFilters.activeCategory = payload;
      state.searchFilters.searchBar = '';
      state.searchFilters.providers = [];
      state.searchFilters.brands = [];
      state.searchFilters.minPrice = 0;
      state.searchFilters.maxPrice = 1000000;
    }
  },
  modules: {
    jv: jsonapiModule(api, config),
  }
});