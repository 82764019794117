<template>
  <b-container>
    <b-row id="favcats">
      <b-col class="cat" lg="2" md="4" cols="6">
        <div class="catspace" v-on:click="loadProducts(favCats[0])">
          <img class="cat-img" v-bind:src="getImgSrc(favCats[0])" />
          <p class="cat-text">{{ favCats[0] }}</p>
        </div>
      </b-col>

      <b-col class="cat" lg="2" md="4" cols="6">
        <div class="catspace" v-on:click="loadProducts(favCats[1])">
          <img class="cat-img" v-bind:src="getImgSrc(favCats[1])" />
          <p class="cat-text">{{ favCats[1] }}</p>
        </div>
      </b-col>

      <b-col class="cat" lg="2" md="4" cols="6">
        <div class="catspace" v-on:click="loadProducts(favCats[2])">
          <img class="cat-img" v-bind:src="getImgSrc(favCats[2])" />
          <p class="cat-text">{{ favCats[2] }}</p>
        </div>
      </b-col>

      <b-col class="cat" lg="2" md="4" cols="6">
        <div class="catspace" v-on:click="loadProducts(favCats[3])">
          <img class="cat-img" v-bind:src="getImgSrc(favCats[3])" />
          <p class="cat-text">{{ favCats[3] }}</p>
        </div>
      </b-col>

      <b-col class="cat d-none d-md-block" lg="2" md="4" cols="6">
        <div class="catspace" v-on:click="loadProducts(favCats[4])">
          <img class="cat-img" v-bind:src="getImgSrc(favCats[4])" />
          <p class="cat-text">{{ favCats[4] }}</p>
        </div>
      </b-col>

      <b-col class="cat d-none d-md-block" lg="2" md="4" cols="6">
        <div class="catspace" v-on:click="loadProducts(favCats[5])">
          <img class="cat-img" v-bind:src="getImgSrc(favCats[5])" />
          <p class="cat-text">{{ favCats[5] }}</p>
        </div>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import categories from "@/lookUpTables/categories";
import ApiRequest from "@/api/apiRequest";

export default {
  data() {
    return {
      favCats: [
        "Technik",
        "Haus & Wohnen",
        "Kleidung & Mode",
        "Familie & Kinder",
        "Freizeit & Garten",
        "Gesundheit & Pflege",
      ],
    };
  },
  methods: {
    getImgSrc(name) {
      return categories(name);
    },
    loadProducts(name) {
      let categories = this.$store.state.categories;
      let id;
      Object.keys(categories).forEach((key) => {
        if (categories[key].name == name) {
          id = categories[key].id;
        }
      });

      if (id !== undefined) {
        this.$store.commit("changeActiveCategory", id);
        ApiRequest.productRequest();
        ApiRequest.filterRequest();

        this.$root.$children[0].routerPush();
      } else {
        //errorpage
      }
    },
  },
};
</script>

<style scoped>
.b-container {
  padding-top: 10px;
}
.cat {
  padding: 0.2rem;
  cursor: pointer;
}
.cat-text {
  font-size: 1.3em;
  color: #939393;
  text-decoration: none;
  text-align: center;
  padding-bottom: 0.6rem;
  margin-bottom: 0;
}
a:hover {
  text-decoration: none;
}
.cat-img {
  display: block;
  margin: auto;
  width: 90%;
  max-width: 130px;
}
@media screen and (max-width: 768px) {
  .catspace {
    background: #f4f4f4;
    padding-top: 5px;
  }
}
@media screen and (min-width: 769px) {
  .catspace {
    background: white;
  }
}
</style>
