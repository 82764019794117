<template>
  <div id="filter-area" class="container py-md-4 px-0 px-md-3">
    <h4 class="d-none d-md-block" :class="hideDefaultName">{{ category }}</h4>

    <h5 id="main-filter-header" class="d-none d-md-block mb-0">Filter</h5>
    <b-button
      id="main-filter-button"
      class="filter-collapse-button d-md-none"
      v-b-toggle.filter-collapse
      >Filteroptionen öffnen</b-button
    >
    <b-collapse id="filter-collapse" class="collapse d-md-block">
      <!--Preisfilter-->
      <h6 class="d-none d-md-block mb-0 filter-header">Preis</h6>
      <b-button
        class="filter-collapse-button d-md-none"
        v-b-toggle.price-collapse
        >Preis</b-button
      >
      <b-collapse id="price-collapse" class="mt-2 d-md-block">
        <b-form id="price-filter" inline class="d-flex flex-nowrap">
          <div class="input-group w-50">
            <b-input
              id="price-lowest"
              type="number"
              v-model="minPrice"
              size="sm"
              @blur="checkMinPrice()"
            ></b-input>
            <p class="pt-1 pl-1">€</p>
          </div>
          <p class="px-md-2 pt-1">–</p>

          <div class="input-group w-50">
            <b-input
              id="price-highest"
              type="number"
              v-model="maxPrice"
              size="sm"
              @blur="checkMaxPrice()"
            ></b-input>
            <p class="pt-1 pl-1">€</p>
          </div>
        </b-form>
      </b-collapse>

      <!--Markenfilter-->
      <h6 class="d-none d-md-block mb-0 filter-header">Marken</h6>
      <b-button
        class="filter-collapse-button d-md-none"
        v-b-toggle.brand-collapse
        >Marken</b-button
      >
      <b-collapse id="brand-collapse" class="mt-2 d-md-block">
        <div v-if="$store.state.searchFilters.options.brands !== null">
          <b-form-checkbox
            class="mb-2"
            v-model="allBrandsSelected"
            id="checkbox-all-brands"
            @change="selectAllBrands"
            >Alle Marken ({{ brandListLength }})</b-form-checkbox
          >
          <b-form-checkbox-group
            id="checkbox-group-brands"
            class="overflow"
            v-model="$store.state.searchFilters.brands"
            :options="$store.state.searchFilters.options.brands"
            name="brands"
            stacked
            @input="checkAllBrandsSelected"
          ></b-form-checkbox-group>
        </div>
        <div v-else class="mx-auto mt-5 loader">
          <moon-loader></moon-loader>
        </div>
      </b-collapse>

      <!--Anbieterfilter-->
      <h6 class="d-none d-md-block mb-0 filter-header">Anbieter</h6>
      <b-button
        class="filter-collapse-button d-md-none"
        v-b-toggle.shop-collapse
        >Anbieter</b-button
      >
      <b-collapse id="shop-collapse" class="mt-2 d-md-block">
        <div v-if="this.$store.state.searchFilters.options.providers !== null">
          <b-form-checkbox
            class="mb-2"
            v-model="allProvidersSelected"
            id="checkbox-all-shops"
            @change="selectAllProviders"
            >Alle Anbieter ({{ providerListLength }})</b-form-checkbox
          >
          <b-form-checkbox-group
            id="checkbox-group-shops"
            class="overflow"
            v-model="$store.state.searchFilters.providers"
            :options="$store.state.searchFilters.options.providers"
            name="providers"
            stacked
            @input="checkAllProvidersSelected"
          ></b-form-checkbox-group>
        </div>
        <div v-else class="mx-auto mt-5 loader">
          <moon-loader></moon-loader>
        </div>
      </b-collapse>

      <b-row>
        <b-col id="reset-button" class="col-md-12">
          <b-button v-on:click="resetFilters()" class="filter-button mt-3"
            >Filter zurücksetzen</b-button
          >
        </b-col>
        <b-col id="filter-button">
          <b-button v-on:click="apiRequest()" class="mt-3"
            >Filter anwenden</b-button
          >
        </b-col>
      </b-row>
    </b-collapse>
  </div>
</template>

<script>
import ApiRequest from "@/api/apiRequest";
import MoonLoader from "vue-spinner/src/MoonLoader.vue";
import Helpers from "../helpers/helpers";

export default {
  components: {
    MoonLoader,
  },
  data() {
    return {
      allProvidersSelected: false,
      allBrandsSelected: false,
    };
  },
  methods: {
    apiRequest() {
      this.$root.$children[0].goToTop();
      ApiRequest.productRequest();

      this.$root.$children[0].routerPush();
    },
    selectAllProviders(checked) {
      if (checked) {
        this.$store.state.searchFilters.providers = Helpers.mapFilterArrToIdArr(
          this.$store.state.searchFilters.options.providers
        );
      } else {
        this.$store.state.searchFilters.providers = [];
      }
    },
    selectAllBrands(checked) {
      if (checked) {
        this.$store.state.searchFilters.brands = Helpers.mapFilterArrToIdArr(
          this.$store.state.searchFilters.options.brands
        );
      } else {
        this.$store.state.searchFilters.brands = [];
      }
    },
    checkAllProvidersSelected() {
      if (
        this.$store.state.searchFilters.providers.length ===
        this.providerListLength
      ) {
        this.allProvidersSelected = true;
      } else {
        this.allProvidersSelected = false;
      }
    },
    checkAllBrandsSelected() {
      if (
        this.$store.state.searchFilters.brands.length === this.brandListLength
      ) {
        this.allBrandsSelected = true;
      } else {
        this.allBrandsSelected = false;
      }
    },
    resetFilters() {
      this.$store.state.searchFilters.providers = [];
      this.$store.state.searchFilters.brands = [];

      this.$store.commit(
        "resetMinPrice",
        this.$store.state.searchFilters.options.minPrice
      );
      this.$store.commit(
        "resetMaxPrice",
        this.$store.state.searchFilters.options.maxPrice
      );
      ApiRequest.productRequest();
    },
    checkMinPrice() {
      let currentMinPrice = parseFloat(
        this.$store.state.searchFilters.minPrice
      );
      let currentMaxPrice = parseFloat(
        this.$store.state.searchFilters.maxPrice
      );
      let realMinPrice = parseFloat(
        this.$store.state.searchFilters.options.minPrice
      );
      let realMaxPrice = parseFloat(
        this.$store.state.searchFilters.options.maxPrice
      );

      let result = currentMinPrice;
      if (result > currentMaxPrice) {
        result = currentMaxPrice;
      }

      if (result === "" || result < realMinPrice) {
        result = realMinPrice;
      } else if (result > realMaxPrice) {
        result = realMaxPrice;
      }

      this.$store.commit("resetMinPrice", result);
    },
    checkMaxPrice() {
      let currentMaxPrice = parseFloat(
        this.$store.state.searchFilters.maxPrice
      );
      let currentMinPrice = parseFloat(
        this.$store.state.searchFilters.minPrice
      );
      let realMinPrice = parseFloat(
        this.$store.state.searchFilters.options.minPrice
      );
      let realMaxPrice = parseFloat(
        this.$store.state.searchFilters.options.maxPrice
      );

      let result = currentMaxPrice;
      if (result < currentMinPrice) {
        result = currentMinPrice;
      }

      if (result === "" || result > realMaxPrice) {
        result = realMaxPrice;
      } else if (result < realMinPrice) {
        result = realMinPrice;
      }

      this.$store.commit("resetMaxPrice", result);
    },
  },
  mounted: function () {
    this.$nextTick(function () {
      // Will be executed when the DOM is ready
      this.checkAllBrandsSelected();
      this.checkAllProvidersSelected();
    });
  },
  computed: {
    category: function () {
      return this.$store.getters.getActiveCategoryName;
    },
    hideDefaultName() {
      return {
        hideCat: this.category == "Kategorie wählen",
      };
    },
    providerListLength() {
      let providers = this.$store.state.searchFilters.options.providers;
      if (providers === null) {
        return [];
      }
      return providers.length;
    },
    brandListLength() {
      let brands = this.$store.state.searchFilters.options.brands;
      if (brands === null) {
        return [];
      }
      return brands.length;
    },
    minPrice: {
      get() {
        let result = this.$store.state.searchFilters.options.minPrice;
        if (this.$store.state.searchFilters.minPrice !== result) {
          result = this.$store.state.searchFilters.minPrice;
        }
        return result;
      },
      set(value) {
        this.$store.state.searchFilters.minPrice = value;
      },
    },
    maxPrice: {
      get() {
        let result = this.$store.state.searchFilters.options.maxPrice;
        if (this.$store.state.searchFilters.maxPrice !== result) {
          result = this.$store.state.searchFilters.maxPrice;
        }
        return result;
      },
      set(value) {
        this.$store.state.searchFilters.maxPrice = value;
      },
    },
  },
};
</script>

<style scoped>
h4 {
  color: #848484;
}
.hideCat {
  visibility: hidden;
}
#main-filter-header {
  background: url(../assets/icon_filter.png) no-repeat 94%/1.1rem #ff9235;
  color: white;
  padding: 0.6rem 0.6rem 0.6rem 1rem;
}
#main-filter-button {
  background: url(../assets/icon_filter.png) no-repeat 94%/1.1rem #ff9235;
  color: white;
}
.filter-collapse-button {
  width: 100%;
  border-radius: 0;
  color: black;
  border: none;
  text-align: left;
  margin-top: 0.5rem;
  padding: 0.6rem 0.6rem 0.6rem 1rem;
}
.filter-header {
  background: #ededed;
  padding: 0.8rem 0.6rem 0.7rem 1rem;
  margin-top: 0.5rem;
  color: black;
}
#price-filter {
  height: 3, 2em;
}
#price-filter input {
  border-radius: 0;
  margin-top: 1rem;
}
#price-collapse p {
  margin-top: 1rem;
}
.overflow {
  max-height: 150px;
  overflow: scroll;
}
.collapsed {
  background: url(../assets/arrow_down_filter.png) no-repeat 94%, #ededed;
}
.not-collapsed {
  background: url(../assets/arrow_up_filter.png) no-repeat 94%, #ededed;
}
#filter-button button {
  border-radius: 0;
  border: none;
  background: url(../assets/icon_check.png) no-repeat 94%/1.1rem, #2c2c2c;
  width: 100%;
  padding: 0.6rem 1.2rem 0.6rem 0;
}

#reset-button button {
  border-radius: 0;
  border: none;
  background: url(../assets/icon_delete.png) no-repeat 94%/1.1rem, #c23131;
  background-size: auto 35%;
  width: 100%;
  padding: 0.6rem 1.2rem 0.6rem 0;
}
.loader {
  max-width: 50px;
}

@media screen and (max-width: 768px) {
  #reset-button {
    padding-right: 3px;
  }
  #filter-button {
    padding-left: 3px;
  }
}
</style>