<template>
  <div>
    <div
      id="sorting"
      class="row flex-nowrap justify-content-end align-items-start ml-md-n4 mr-xl-1 pr-xl-5"
    >
      <p id="sorting-label" class="col col-md-2">Sortierung</p>

      <div class="col col-md-5 pr-0">
        <b-form-select
          class="input-xs"
          v-model="filterSelected"
          :options="filterOptions"
          @change="filter"
        ></b-form-select>
      </div>

      <div class="col col-md-5 pr-0">
        <b-form-select
          class="input-xs"
          v-model="sortOrderSelected"
          :options="sortOderOptions"
          @change="sortOrder"
        ></b-form-select>
      </div>
    </div>
  </div>
</template>

<script>
import ApiRequest from "@/api/apiRequest";
import SortFields from "@/sorting/sortFields";

const ASC = "asc";
const DESC = "desc";

export default {
  data() {
    return {
      filterSelected: this.$store.state.sorting.criterion,
      filterOptions: [
        { value: SortFields.PRICE, text: "Preis" },
        { value: SortFields.NAME, text: "Produktname" },
        //   { value: SortFields.BRAND, text: "Marke" },  // #7701
        { value: SortFields.PROVIDER, text: "Anbieter" },
      ],
      sortOrderSelected: this.$store.state.sorting.orderIsAsc ? ASC : DESC,
      sortOderOptions: [
        { value: ASC, text: "aufsteigend" },
        { value: DESC, text: "absteigend" },
      ],
    };
  },
  methods: {
    filter() {
      switch (this.filterSelected) {
        case SortFields.PRICE:
          this.storeSortCriterion(SortFields.PRICE);
          break;
        case SortFields.NAME:
          this.storeSortCriterion(SortFields.NAME);
          break;
        case SortFields.BRAND:
          this.storeSortCriterion(SortFields.BRAND);
          break;
        case SortFields.PROVIDER:
          this.storeSortCriterion(SortFields.PROVIDER);
          break;
      }
      this.$root.$emit("page-event");
    },
    sortOrder() {
      if (this.sortOrderSelected == ASC) {
        this.storeSortOrder(true);
      } else if (this.sortOrderSelected == DESC) {
        this.storeSortOrder(false);
      }
      this.$root.$emit("page-event");
    },
    storeSortCriterion(criterion) {
      this.$store.commit("changeSortingCriterion", criterion);
      this.apiRequest();
    },
    storeSortOrder(isAsc) {
      this.$store.commit("changeOrderIsASC", isAsc);
      this.apiRequest();
    },
    apiRequest() {
      ApiRequest.productRequest();
      this.$root.$children[0].routerPush();
    },
  },
};
</script>

<style scoped>
#sort-price {
  display: inline-block;
}
#sorting {
  padding: 0;
}

#sorting-label {
  font-size: 1em;
  text-align: right;
  padding: 2px 0 0;
  min-width: 4rem;
}

.custom-select {
  background: linear-gradient(
    to right,
    rgba(13, 85, 218, 0) 85%,
    rgb(80, 142, 235) 20%
  );
  font-size: 0.9em;
  font-weight: bold;
  min-width: 120px;
}
.input-xs {
  height: 80%;
  padding: 0px 15px 0px 4px;
  max-width: 200px;
}

@media screen and (max-width: 769px) {
  #sorting {
    justify-content: space-between;
    padding-left: 1rem;
    padding-right: 1rem;
  }
  #sorting-label {
    text-align: left;
  }
}

@media screen and (max-width: 396px) {
  #sorting {
    padding-left: 0;
    margin-bottom: 0.8rem;
  }
  #sorting-label {
    display: none;
  }
}

@media screen and (max-width: 992px) and (min-width: 769px) {
  #sorting {
    padding-left: 0;
  }
  #sorting-label {
    font-size: 0.9em;
    padding-top: 4px;
  }
  .custom-select {
    font-size: 0.8em;
  }
}
</style>