<template>
  <div id="app" class="d-flex flex-column min-vh-100">
    <HeaderArea />
    <div id="content">
      <router-view />
    </div>
    <Cookie ref="cookieComponent" />
    <FooterArea />
  </div>
</template>

<script>
import HeaderArea from "./components/HeaderArea.vue";
import Cookie from "./components/Cookie.vue";
import FooterArea from "./components/FooterArea.vue";
import ApiRequest from "@/api/apiRequest";
import UrlReader from "./router/urlReader";
import UrlBuilder from "./router/urlBuilder";

export default {
  name: "App",
  head: {
    link: [
      {
        rel: "canonical",
        href: "",
        id: "canonical",
      },
    ],
  },
  data() {
    return {
      urlBuilder: "",
      urlValues: "",
    };
  },
  components: {
    HeaderArea,
    Cookie,
    FooterArea,
  },
  watch: {
    //Check cookies are accepeted.
    $route(component) {
      this.goToTop();
      if (component.name !== "DSGVO") {
        this.showCookieWindow();
      }
      if (
        component.name === "DSGVO" ||
        component.name === "Impressum" ||
        component.name === "Startpage"
      ) {
        this.setCanonicalUrl();
      }
    },
  },
  mounted: function () {
    this.$nextTick(function () {
      this.urlBuilder = new UrlBuilder();
      this.showCookieWindow();
      ApiRequest.categoryRequest(this.initAfterCategories);
    });
  },
  methods: {
    routerPush() {
      let url = this.urlBuilder.buildUrl(
        this.$store.state.searchFilters,
        this.$store.state.pagination.page
      );
      this.$root.$children[0].setCanonicalUrl(url);

      this.$router.push(url).catch((error) => {
        if (error.name != "NavigationDuplicated") {
          throw error;
        }
      });
    },
    setCanonicalUrl(url) {
      let component = this.$router.history.current;
      if (
        component.name === "DSGVO" ||
        component.name === "Impressum" ||
        component.name === "Startpage"
      ) {
        url = component.path;
      } else {
        if (url === undefined) {
          url = this.urlBuilder.buildUrl(
            this.$store.state.searchFilters,
            this.$store.state.pagination.page
          );
        }
      }

      let e = document.getElementById("canonical");
      e.href = url;
    },
    createMenuItemHrefs() {
      this.$store.state.categories.forEach((element) => {
        element.href = this.urlBuilder.buildUrlForCategoryItem(
          element.slug,
          this.$store.state.searchFilters,
          this.$store.state.pagination.page
        );
      });
    },
    showCookieWindow() {
      this.$refs.cookieComponent.show();
    },
    goToTop() {
      document.body.scrollTop = 0; // For Safari
      document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
    },
    initAfterCategories() {
      this.createMenuItemHrefs();
      if (this.$router.history.current.name === "Results") {
        this.init();
      } else {
        this.setCanonicalUrl();
      }
    },
    init() {
      this.urlValues = this.readUrl();
      this.overwritingValues();
      ApiRequest.filterRequest(this.setCanonicalUrl);
      this.overwritingFilters();
    },
    readUrl() {
      let path = this.$router.history.current.path;
      let query = this.$router.history.current.query;

      return UrlReader.readUrl(path, query);
    },
    overwritingValues() {
      this.$store.commit(
        "changeActiveCategory",
        this.urlValues["activeCategory"]
      );
      this.$store.commit("changePaginationPage", this.urlValues["page"]);
      this.$store.commit("changeSearchName", this.urlValues["search"]);
    },
    overwritingFilters() {
      this.$store.commit("resetMinPrice", this.urlValues["minPrice"]);
      this.$store.commit("resetMaxPrice", this.urlValues["maxPrice"]);
      this.$store.commit("changeBrands", this.urlValues["brands"]);
      this.$store.commit("changeProviders", this.urlValues["providers"]);
      ApiRequest.productRequest();
      this.urlValues = "";
    },
    getUrlBuilder() {
      return this.urlBuilder;
    },
  },
};
</script>

<style>
@import url("https://fonts.googleapis.com/css?family=Roboto+Condensed|Roboto");
@import "css/pagination.css";

#app {
  font-family: "Roboto Condensed", Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: left;
  color: #2c3e50;
  min-width: 300px;
  margin: 0 auto;
  height: 100%;
}

body {
  overflow-y: scroll;
  height: 101%;
}
</style>
